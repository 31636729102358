import React, { useContext, useEffect, useRef } from 'react'

import OkayIcon from '../../../../assets/images/illustration_positive.svg'
import { DataContext } from '../../../../context/data'
import { useIntersection } from '../../../../hooks/useIntersection'

import {
  Container,
  Text,
  TextPrincipal,
  BlockText,
  Logo,
  HubSpotForm,
} from './content_styles'

type ContentProps = {
  id?: string
  className?: string
}

export const ContentForLeads: React.FC<ContentProps> = ({ id, ...props }) => {
  const ref = useRef(null as unknown as HTMLDivElement)

  const {
    body: {
      contact: {
        for_leads: { title, subtitle },
      },
    },
  } = useContext(DataContext)
  useIntersection(ref, '100px', true)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/embed/v2.js'
    script.async = true
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window && window.hbspt) {
        window.hbspt.forms.create({
          target: '#hubspot-contact-form',
          region: 'na1',
          portalId: '39550973',
          formId: '62a7bab2-24dd-4b9c-ac00-b20e474bd376',
        })
      }
    })
  }, [props])

  return (
    <Container ref={ref} {...props} id={id}>
      <BlockText>
        <TextPrincipal>
          {title}
          <Logo src={OkayIcon} />
        </TextPrincipal>
        <Text>{subtitle}</Text>
      </BlockText>
      <HubSpotForm id="hubspot-contact-form" />
    </Container>
  )
}

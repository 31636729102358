/* eslint-disable indent */
import styled from 'styled-components'

export const Container = styled.div`
  margin: ${({ theme }) => theme.spacing.internal};
  margin-top: calc(${({ theme }) => theme.spacing.xl} * 1);
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 1120px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  align-self: center;

  @media (max-width: calc(${({ theme }) => theme.breakpoints.desktop} - 1px)) {
    margin: 0;
  }

  @media (max-width: calc(${({ theme }) => theme.breakpoints.desktop} - 1px)) {
    gap: 1rem;
    height: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
`
type LogoProps = {
  compact?: boolean
}

export const Logo = styled.img<LogoProps>`
  height: ${({ compact, theme }) =>
    compact ? theme.font.sizes.xxl : theme.font.sizes.xxxl};
`

export const BlockText = styled.div`
  width: 100%;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: calc(${({ theme }) => theme.breakpoints.desktop} - 1px)) {
    width: 90%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    margin-bottom: ${({ theme }) => theme.spacing.internal};
  }
`
export const BlockImage = styled.div``

export const Content = styled.div`
  margin: ${({ theme }) => theme.spacing.internal};
  display: flex;
  height: calc(100vh - 40px);
  width: 100%;
  max-width: 1120px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  align-self: center;

  @media (max-width: calc(${({ theme }) => theme.breakpoints.desktop} - 1px)) {
    gap: 1rem;
    height: auto;
    flex-wrap: wrap;
  }
`

export const Text = styled.div`
  font-size: ${({ theme }) => theme.font.sizes.md};
  font-family: ${({ theme }) => theme.font.family.sliver};
  line-height: ${({ theme }) => theme.font.lineHeight.distant};
  letter-spacing: 2%;
  width: 100%;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
`
export const TextPrincipal = styled.h2`
  font-size: ${({ theme }) => theme.font.sizes.xxxl};
  font-family: ${({ theme }) => theme.font.family.sliver};
  font-weight: normal;
  margin: 0;
  line-height: ${({ theme }) => theme.font.lineHeight.medium};
  display: flex;
  align-items: flex-end;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    width: 100%;

    font-size: ${({ theme }) => theme.font.sizes.xxl};
    font-family: ${({ theme }) => theme.font.family.sliver};
    font-weight: normal;
    margin: 0;
    line-height: ${({ theme }) => theme.font.lineHeight.medium};
  }

  img {
    height: 6rem;
  }
`

export const HubSpotForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: end;
  background-color: ${({ theme }) => theme.primary.light};

  @media (max-width: calc(${({ theme }) => theme.breakpoints.desktop} - 1px)) {
    width: 90%;
    align-items: center;

    .hs-fieldtype-intl-phone{
      display: block;
    }
  }

  form {
    max-width: 420px;
    width: 100%;
    background-color: ${({ theme }) => theme.primary.light};
    input,
    textarea {
      width: 100%;
      margin-bottom: 20px;
      background-color: ${({ theme }) => theme.primary.light};
      border: none;
      border-bottom: 1px solid #342b1d;
      height: 40px;
      padding: 5px;
      font-size: ${({ theme }) => theme.font.sizes.xs};
      font-family: ${({ theme }) => theme.font.family.default};

      &:focus {
        outline: none;
        border-color: #342b1d;
      }
    }

    textarea {
      height: 100px;
    }

    span {
      font-family: ${({ theme }) => theme.font.family.default};
      font-size: ${({ theme }) => theme.font.sizes.xs};
      color: ${({ theme }) => theme.colors.neutral.dark};
    }
    
    select {
      width: 50% !important;
      padding: 10px;
      font-size: 16px;
      border: none;
      border-bottom: 1px solid #333;
      background-color: transparent;
      color: #333;
      outline: none;
      cursor: pointer;

      &:focus {
          border-bottom: 1px solid #555;
        }
      }

    .hs-fieldtype-intl-phone {
      display:flex;
      gap: 12px;
      padding-bottom: 24px;
    }

    .hs-fieldtype-intl-phone.hs-input> select{
      width: 50% !important;
    }

  input[type='submit'] {
    background-color: ${({ theme }) => theme.primary.default};
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    color: white;
    max-width: 420px;
  }
`

import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import {
  ContactForLeads,
  ContactForCustomers,
} from './screens/contact/contact_screen'
import { WhoWeSome } from './screens/who_we_some'
import { Home } from './screens/home'
import { FarMeBox } from './screens/farme_box'
import { Careers } from './screens/careers'
import { Clinics } from './screens/clinics'
import { ForCustomers } from './screens/customers'
import { ForCompanies } from './screens/companies'
import { ForDoctors } from './screens/doctors'
import { PSP } from './screens/psp'

const routes = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/blog',
    loader: () => {
      window.location.replace('https://blog.farme.com.br')
      return null
    },
  },
  {
    path: '/para-clientes/*',
    children: [
      {
        path: '',
        element: <ForCustomers />,
      },
      {
        path: 'quem-somos',
        element: <WhoWeSome />,
      },
      {
        path: 'farme-box',
        element: <FarMeBox />,
      },
      {
        path: 'carreiras',
        element: <Careers />,
      },
    ],
  },
  {
    path: '/para-instituicoes',
    element: <ForCompanies />,
  },
  {
    path: '/para-medicos',
    children: [
      {
        path: '',
        element: <ForDoctors />,
      },
      {
        path: 'clinica',
        element: <Clinics />,
      },
      {
        path: 'psp',
        element: <PSP />,
      },
    ],
  },
  {
    path: '/contato',
    children: [
      {
        path: '',
        element: <ContactForLeads />,
      },
      {
        path: 'cliente',
        element: <ContactForCustomers />,
      },
    ],
  },
  {
    path: '/politica-de-privacidade',
    loader: () => {
      window.location.replace(
        'https://assets.farme.com.br/pdfs/farme_protecao_de_dados.pdf'
      )
      return null
    },
  },
  {
    path: '*',
    element: <Home />,
  },
])

export const Router: React.FC = () => {
  return <RouterProvider router={routes} />
}

/* eslint-disable indent */
import styled from 'styled-components'

export const ButtonAction = styled.button`
  width: 82px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background: rgba(255, 200, 117, 1);
  box-shadow: 0px 3px 0px rgb(222, 170, 91);
  border: 0;
  padding: 30px;
  font-size: 12px;
  font-family: 'Inter';
`

export const Image = styled.img`
  margin-bottom: 10px;
`

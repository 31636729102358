import React from 'react'
import { WhatsappButton } from './whatsapp_button_styles'
import { ReactComponent as ImageWhatsappLogo } from '../../assets/images/black_whatsapp_logo.svg'

type ButtonProps = {
  label?: string
}
export const WhatsappButtonAction: React.FC<ButtonProps> = ({ label }) => (
  <WhatsappButton
    onClick={() =>
      window.open('https://api.whatsapp.com/send?phone=5511913278757')
    }
  >
    <ImageWhatsappLogo />
    {label}
  </WhatsappButton>
)

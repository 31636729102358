export default {
  ImageBoxWhoWeAre: require('./images/home-image.png'),
  ImageCoverFameBox: require('./images/imagem-cover-farmebox.png'),
  ImageBoxPointingLeft: require('./images/box-direction-left.png'),
  ImageBoxPointingCenter: require('./images/box-direction-center.png'),
  ImageBoxPointingRight: require('./images/box-direction-right.png'),
  ImageHandWithDrugs: require('./images/hand-with-drugs.png'),
  ImagePersonReceivedBox: require('./images/person-received-box.png'),
  ImageBill: require('./images/bill.png'),
  ImagePerson: require('./images/person_2.png'),
  ImagePeopleInStore: require('./images/people-in-store.png'),
  ImagePeopleInLab: require('./images/person-in-lab.png'),
  ImageBoxReceived: require('./images/box-received.png'),
  ImageOldManPhone: require('./images/old-man-phone.png'),
  ImageOldWomanInComputer: require('./images/old-woman-in-computer.png'),
  ImageDoctorWihPhone: require('./images/doctor-with-phone.png'),
  ImageDoctorLooking: require('./images/doctor-looking.png'),
  ImageBanner: require('./images/banner.png'),
  ImageBannerOlderWoman: require('./images/banner2.png'),
  ImageMedicalCare: require('./images/medical-care.png'),
  ImageOldWomanSited: require('./images/old-woman-sited.png'),
  ImageWomanWithTablet: require('./images/woman-with-tablet.png'),
  ImageAsianOldWoman: require('./images/asian-old-woman.png'),
  ImagePhoneAtHand: require('./images/phone-at-hand.png'),
  ImageOldManWithAPhone: require('./images/old-man-with-a-phone.png'),
  ImageWatchDoctor: require('./images/watch-doctor.png'),
  ImageManWithHeadphone: require('./images/man-with-headphone.png'),
  ImagePharmaceuticalWoman: require('./images/pharmaceutical-woman.png'),
  ImageWomanAtTree: require('./images/woman-at-tree.png'),
  ImageDrugsInTable: require('./images/drugs-in-table.png'),
  ImageOldWomanWithSmile: require('./images/old-woman-with-smile.png'),
  ImageOldManWithHeadphones: require('./images/old-man-with-headphones.png'),
  ImageOldManWithPhoneInPark: require('./images/old-with-phone-in-park.png'),
  ImageBoxPlusMao: require('./images/box_mao.png'),
  ImageFrontBoxZoom: require('./images/front_box_zoom.png'),
  ImagePills: require('./images/pills.png'),
  ImageBannerWhoWeSome: require('./images/img_banner_quem_somos.jpg'),
  ImageMosaicFarme: require('./images/mosaic_farme.png'),
  ImageCheckingBoxesFarme: require('./images/checking_boxes.png'),
  ImageOldManLookingBox: require('./images/old_man_looking_box.png'),
  ImageZoomBox: require('./images/zoom_box.png'),
  ImageLab: require('./images/lab.png'),
  ImageRoadClinicsToFarme: require('./images/road_clinics_to_farme.png'),
  ImageRoadCompaniesToFarme: require('./images/road_companie_to_farme.png'),
  ImageAttendanceFarme: require('./images/attendance_farme.png'),
  ImageAttendanceInNotebookFarme: require('./images/attendance_in_notebook.png'),
  ImageAttendanceShowingPillsFarme: require('./images/attendance_showing_pills.png'),
  ImageAttendanceCallCenterFarme: require('./images/attendance_call_center.png'),
  ImagePillsInHand: require('./images/pills_in_hand.png'),
  ImageWifeAndHusband: require('./images/wife_and_husband.png'),
  ImagePillsStackInHand: require('./images/pills_stack_in_hand.png'),
  ImageDoctorAtPhone: require('./images/doctor_at_phone.png'),
  ImageRightPointerBanner: require('./images/box_right_pointer_banner.png'),
  ImageBoxRotate: require('./images/box_rotate.png'),
  ImageCarinaNeves: require('./images/carina_neves.png'),
  ImageBoxZoomIn: require('./images/saquinho_box.png'),
  ImageLoupe: require('./images/loupe.svg'),
  ImagePrivacyPolicyBackground: require('./images/pharmaceutical-woman-black-and-white.png'),
  ImageBlackFriday: require('./images/blackfriday.svg'),
}

export const people = {
  MarinaFounder: require('./images/people/marina.png'),
  LucianaFounder: require('./images/people/luciana.png'),
  SammillaFounder: require('./images/people/samilla.png'),
  CarinaNevesPharmaceutical: require('./images/people/carina_neves.png'),
  MarianaMesquitaSupport: require('./images/people/mariana_mesquita.png'),
  GabriellaCampera: require('./images/people/gabriella_campera.png'),
  LuizFelipeCustomer: require('./images/people/luiz_felipe.png'),
  PatriciaFreitasCustomer: require('./images/people/patricia_freitas.png'),
  ThamaraQuintaoCustomer: require('./images/people/thamara_quintao.png'),
  RosaGomesCustomer: require('./images/people/rosa_gomes.png'),
  FelipeAmbrosioDesigner: require('./images/people/felipe_ambrosio.png'),
  ClaraRochaProduction: require('./images/people/clara_rocha.png'),
  PedroMottaHead: require('./images/people/pedro_motta.png'),
  RafaelaStranigherOPS: require('./images/people/rafaela_stranigher.png'),
  RaimundoCostaCustomer: require('./images/people/raimundo_costa.png'),
  MarisaDonatoCustomer: require('./images/people/marisa_donato.png'),
  TalesMachadoCustomer: require('./images/people/tales_machado.png'),
  NadirPessoaCustomer: require('./images/people/nadir_pessoa.png'),
  RonaldoCaetanoCustomer: require('./images/people/ronaldo.png'),
  LuisClaudioCustomer: require('./images/people/luis_claudio.png'),
  HagaboPharmaceutical: require('./images/people/hagabo.png'),
  DirceuMoraesCustomer: require('./images/people/dirceu_moraes.png'),
  SelenaFernandesCustomer: require('./images/people/selena_fernandes.png'),
  JoanaBittencourtCustomer: require('./images/people/joana_bittencourt.png'),
  RubiamaraSoaresCustomer: require('./images/people/rubiamara_soares.png'),
  LilianSoutoCustomer: require('./images/people/lilian_souto.png'),
  AnaCimblerisCustomer: require('./images/people/ana_cimbleris.png'),
  ArethaAtaideCustomer: require('./images/people/aretha_ataide.png'),
  CarlosArantesCustomer: require('./images/people/carlos_arantes.png'),
  GustavoBeloubeCustomer: require('./images/people/gustavo_beloube.png'),
}

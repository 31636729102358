import React, { useContext, useRef } from 'react'

import QuotationIllustration from '../../../../assets/images/quotation.svg'
import { DataContext } from '../../../../context/data'
import { Slides, Header } from '../../../../ds'
import { useIntersection } from '../../../../hooks/useIntersection'

import {
  Container,
  Content,
  Text,
  BlockText,
  Quotation,
  TextPrincipal,
} from './testimonies_employee_styles'

type TestimoniesEmployeeProps = {
  id?: string
  className?: string
}

export const TestimoniesEmployee: React.FC<TestimoniesEmployeeProps> = ({
  id,
}) => {
  const {
    body: {
      for_doctors: { testimonies: pageTestimoniesEmployee },
    },
  } = useContext(DataContext)
  const ref = useRef(null as unknown as HTMLDivElement)
  useIntersection(ref, '0px', true)

  type LeftSideProps = {
    testimony: string
    name: string
    job: string
  }
  const LeftSide = ({ testimony, name, job }: LeftSideProps) => (
    <BlockText>
      <Quotation src={QuotationIllustration} />
      <TextPrincipal size={testimony.length}>{testimony}</TextPrincipal>
      <Text>
        {name}
        {job && `, ${job}`}
      </Text>
    </BlockText>
  )

  return (
    <Container id={id}>
      <Content ref={ref}>
        <Header
          description={pageTestimoniesEmployee.description}
          title={pageTestimoniesEmployee.title}
        />
        <Slides
          slides={pageTestimoniesEmployee.testimonies.map(
            ({ testimony, name, job }) => ({
              LeftSide: (
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                <LeftSide testimony={testimony!} name={name} job={job} />
              ),
              key: name.split(' ').join('-'),
            })
          )}
        />
      </Content>
    </Container>
  )
}

import React, { useContext, useRef } from 'react'

import images from '../../../../assets/images'
import { Banner as BannerComponent } from '../../../../ds'
import { DataContext } from '../../../../context/data'

import { Container } from './banner_styles'
import { useIntersection } from '../../../../hooks/useIntersection'
import { addingCurrentQueryParams } from '../../../../components/helpers/url_parser'
import { urlJornada } from '../../../../utils/variables'

type BannerProps = {
  id?: string
  className?: string
}

export const Banner: React.FC<BannerProps> = ({ id }) => {
  const {
    body: {
      clinics: { banner },
    },
  } = useContext(DataContext)
  const ref = useRef(null as unknown as HTMLDivElement)
  useIntersection(ref, '100px', true)

  return (
    <Container ref={ref} id={id}>
      <BannerComponent
        image={images.ImageWomanAtTree}
        title={banner.title}
        subtitle={banner.subtitle}
        button={{
          text: 'Monte sua Box',
          onClick: () => window.open(addingCurrentQueryParams(urlJornada)),
        }}
        positionImageInTablet="right"
      />
    </Container>
  )
}

import React, { useContext, useLayoutEffect } from 'react'
import { Footer, Menu, Testimonies, CallToAction } from '../../components'
import { SubjectContext } from '../../context/subject'
import { Subject } from '../../context/subject/subject'
import {
  Main,
  Arguments,
  KnowMe,
  Reasons,
  FarMeBox as FarMeBoxComponent,
  Banner,
  Doubt,
} from './body'
import { Body, Container } from './farme_box_styles'

export const FarMeBox = () => {
  const { setSubject } = useContext(SubjectContext)
  useLayoutEffect(() => {
    setSubject(Subject.customer)
  }, [])

  return (
    <Container>
      <Body>
        <Menu />
        <Main id="quem-somos" />
        <Arguments id="argumentos" />
        <KnowMe id="conheca-nos" />
        <FarMeBoxComponent id="farme-box" />
        <Reasons id="razoes" />
        <Doubt id="duvidas-frequentes" />
        <Banner id="banner" />
        <Testimonies id="depoimentos" />
        <Footer id="sobre" />
        <CallToAction />
      </Body>
    </Container>
  )
}

import React, { createContext, useEffect, useState } from 'react'
import { useSession } from '../../hooks/useSession'

type ScreenTypes = {
  showSearchOnNavbar: boolean
  fixedNavbar: boolean
  totalDistanceToTop: number
}

export const ScreenContext = createContext<ScreenTypes>({
  fixedNavbar: false,
  showSearchOnNavbar: false,
  totalDistanceToTop: 0,
})

export const ScreenProvider = ({ children }: { children: React.ReactNode }) => {
  const [shouldShowBanner] = useSession()

  const isMobile = window.innerWidth <= 1024
  const isHomePage = window.location.pathname === '/'

  const [fixedNavbar, setFixedNavbar] = useState(false)
  const [showSearchOnNavbar, setShowSearchOnNavbar] = useState(false)

  const sizeBannerNavbar = shouldShowBanner.keeping_open ? 102 : 150

  const distanceToNavBar = isMobile ? 40 : 90
  const distanceToNavBarOpened = isMobile ? 166 : 148

  const sizeNavbar = shouldShowBanner.keeping_open
    ? distanceToNavBarOpened
    : distanceToNavBar

  const totalDistanceToTop = sizeNavbar + (fixedNavbar ? 0 : sizeBannerNavbar)

  function checkSearchIsVisible() {
    if (!isHomePage) {
      setShowSearchOnNavbar(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (
        window.scrollY <= sizeBannerNavbar &&
        isMobile &&
        shouldShowBanner.keeping_open
      ) {
        setFixedNavbar(false)
      } else {
        setFixedNavbar(true)
      }
      if (
        window.scrollY > (shouldShowBanner.keeping_open ? 280 : 180) ||
        !isHomePage
      ) {
        setShowSearchOnNavbar(true)
      } else {
        setShowSearchOnNavbar(false)
      }
    })
    checkSearchIsVisible()
  }, [])

  return (
    <ScreenContext.Provider
      value={{
        showSearchOnNavbar,
        totalDistanceToTop,
        fixedNavbar,
      }}
    >
      {children}
    </ScreenContext.Provider>
  )
}

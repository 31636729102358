import React, { useContext, useEffect, useRef, useState } from 'react'
import { DataContext } from '../../context/data'
import { useIntersection } from '../../hooks/useIntersection'
import { Header } from '../../ds'
import {
  Container,
  Content,
  BlockNumbers,
  BlockNumber,
  Number,
  SubtitleNumber,
  Message,
} from './numbers_styles'

type NumbersProps = {
  id?: string
  className?: string
}

const contentId = 'container-numbers'

export const Numbers: React.FC<NumbersProps> = ({ id }) => {
  const {
    body: {
      common_pages: { numbers },
    },
  } = useContext(DataContext)
  const ref = useRef(null as unknown as HTMLDivElement)
  useIntersection(ref, '0px', true)

  return (
    <Container id={id}>
      <Content id={contentId} ref={ref}>
        <Header full description={numbers.description} title={numbers.title} />
        <BlockNumbers>
          {numbers.infos.map((info) => (
            <BlockNumber key={info.legend}>
              <NumberAnimated valueFinal={info.value} isMore={info.isMore} />
              <SubtitleNumber
                dangerouslySetInnerHTML={{ __html: info.legend }}
              />
            </BlockNumber>
          ))}
        </BlockNumbers>
        <Message dangerouslySetInnerHTML={{ __html: numbers.message }} />
      </Content>
    </Container>
  )
}

type NumberAnimatedProps = {
  valueFinal: number
  isMore: boolean
}

const NumberAnimated = ({ valueFinal, isMore }: NumberAnimatedProps) => {
  const ref = useRef(null as unknown as HTMLSpanElement)

  const [isVisible, setVisibility] = useState(false)
  const [value, setValue] = useState(0)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const element = document.getElementById(contentId)
      const reactElement = element?.getBoundingClientRect()
      if (reactElement && reactElement?.y < reactElement?.height + 100) {
        setVisibility(true)
      }
    })
  }, [])

  useEffect(() => {
    if (isVisible) {
      const interval = async () => {
        await new Promise(() => {
          setTimeout(() => {
            if (valueFinal > 15000) {
              setValue(value + 191)
            } else if (valueFinal >= 10000) {
              setValue(value + 101)
            } else {
              setValue(value + 1)
            }
          }, 20)
        })
      }
      if (value < valueFinal) {
        interval()
      } else if (value >= valueFinal) {
        setValue(valueFinal)
      }
    }
  }, [value, isVisible])

  return (
    <Number ref={ref} id={`animated-number-${valueFinal}`}>
      {isMore && '+'}
      {transformNumberToString(value)}
    </Number>
  )
}

const transformNumberToString = (value: number) => {
  if (value >= 1000) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }
  return value.toString()
}

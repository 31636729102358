import React, { useState, KeyboardEvent } from 'react'
import ImageLoupe from '../../assets/images/loupe.svg'

import {
  InstantSearch,
  connectAutoComplete,
  Configure,
} from 'react-instantsearch-dom'
import { searchClient } from './typesense'
import {
  Container,
  DivIcon,
  HitsResultsOrSearchFor,
  Hr,
  Icon,
  Input,
  Paragraph,
  SearchBarStyle,
  Section,
  SectionHits,
  SpanIcon,
} from './search_styles'

import { TYPESENSE_COLLECTION } from '../../config'
import { addingCurrentQueryParams } from '../helpers/url_parser'
import { urlJornada } from '../../utils/variables'

interface Medicine {
  concentracao: string
  nome: string
  ean: string
}

type TAutocomplete = {
  hits: Array<Medicine>
  currentRefinement: string
  refine: (value: string) => void
}

type QueryProps = {
  q?: string
}

const Autocomplete = ({ hits, currentRefinement, refine }: TAutocomplete) => {
  const [searchOn, setSearchOn] = useState(false)

  const isMobile = window.innerWidth < 1024

  const urlMinhaFarmeMedicamentos = `${urlJornada}/busca`

  const createQueryParams = (q: string) => {
    const queryParams: QueryProps = {
      q: q,
    }
    return queryParams
  }

  function createUrl() {
    return addingCurrentQueryParams(
      `${urlMinhaFarmeMedicamentos}`,
      createQueryParams(currentRefinement)
    )
  }

  function searchUrl() {
    closeHits()
    window.open(createUrl(), '_blank')
  }

  function searchOnKey(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      searchUrl()
    } else if (event.key === 'Escape') {
      closeHits()
    }
  }

  const closeHits = () => {
    const input = document.getElementById('searchMedicineInput')
    setTimeout(() => {
      input?.blur()
      setSearchOn(false)
    }, 200)
  }
  const anchorToNavSearch = () => {
    if (isMobile) {
      const el = document.getElementById('searchMedicineInput')
      el?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    <SearchBarStyle currentRefinement={currentRefinement}>
      <Section>
        <Input
          id="searchMedicineInput"
          value={currentRefinement}
          type="text"
          placeholder="Pesquise o medicamento"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
          onClick={anchorToNavSearch}
          onChange={(event) => refine(event.target.value)}
          onKeyUp={currentRefinement ? searchOnKey : () => ''}
          onFocus={() => setSearchOn(true)}
          onBlur={closeHits}
        />

        <DivIcon
          currentRefinement={currentRefinement}
          onClick={currentRefinement ? searchUrl : () => ''}
        >
          <SpanIcon>
            <Icon src={ImageLoupe} />
          </SpanIcon>
        </DivIcon>
      </Section>
      {currentRefinement && searchOn && (
        <SectionHits>
          <Hr />
          <HitsResultsOrSearchFor href={createUrl()} target="_blank">
            <Paragraph>
              Buscar por: <strong>{currentRefinement}</strong>
            </Paragraph>
          </HitsResultsOrSearchFor>

          {hits.map((hit) => (
            <HitsResultsOrSearchFor
              key={hit.ean}
              href={addingCurrentQueryParams(
                `${urlMinhaFarmeMedicamentos}`,
                createQueryParams(`${hit.nome} ${hit.concentracao}`)
              )}
              target="_blank"
              onClick={closeHits}
            >
              <Paragraph>
                {hit.nome} {hit.concentracao}
              </Paragraph>
            </HitsResultsOrSearchFor>
          ))}
        </SectionHits>
      )}
    </SearchBarStyle>
  )
}

const CustomAutocomplete = connectAutoComplete(Autocomplete)

export const SearchBar = ({ id }: { id: string }) => {
  return (
    <>
      <Container id={id} className="containerSearchBar">
        <InstantSearch
          indexName={TYPESENSE_COLLECTION}
          searchClient={searchClient}
          stalledSearchDelay={1000}
        >
          <Configure hitsPerPage={4} enablePersonalization={true} />
          <CustomAutocomplete />
        </InstantSearch>
      </Container>
    </>
  )
}

import styled from 'styled-components'

export const WhatsappButton = styled.div`
  border: none;
  width: 160px;
  height: 40px;
  font-weight: 600;
  background: #42d786;
  border-radius: 37px;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  text-align: center;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
`

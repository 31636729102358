import React from 'react'
/* import { ButtonBox } from '../../ds'
import { addingCurrentQueryParams } from '../helpers/url_parser'
import { Container } from './call_to_action_styles'
import useDevice from '../../hooks/useDevice'
import { createQueryParams } from '../helpers/create_query_params' */

export const CallToAction = () => {
  // const { isDesktop } = useDevice()

  return <></>

  /* if (isDesktop) return <></>

  return (
    <Container>
      <ButtonBox
        onClick={() =>
          window.open(
            addingCurrentQueryParams(
              'https://minha.farme.com.br',
              createQueryParams('monte_sua_box')
            )
          )
        }
        label="Montar Box"
      />
    </Container>
  ) */
}

import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter'
import { TYPESENSE_API_KEY, TYPESENSE_HOST } from '../../config'

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: TYPESENSE_API_KEY,
    nodes: [
      {
        host: TYPESENSE_HOST,
        port: 443,
        protocol: 'https',
      },
    ],
    cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
  },
  additionalSearchParameters: {
    includeFields: 'apresentacao,concentracao,nome,principio_ativo',
    query_by: 'apresentacao,nome,principio_ativo',
    group_by: 'concentracao,nome',
    group_limit: 1,
  },
})

export const searchClient = typesenseInstantsearchAdapter.searchClient

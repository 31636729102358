function defaultParams(queryParams: Record<string, string>) {
  const searchParams = new URLSearchParams(document.location.search)
  const currentParams = { ...queryParams }

  searchParams.forEach((value, key) => {
    currentParams[key] = value
  })

  return new URLSearchParams(currentParams).toString()
}

export const addingCurrentQueryParams = (
  url: string,
  queryParams?: Record<string, string>
) => {
  const params = defaultParams(queryParams || {})

  if (url[0] === '/') {
    return `${url}?`.concat(params)
  }

  const newUrl = new URL(url)

  newUrl.search = params
  return newUrl.toString()
}

import React, { useContext, useRef } from 'react'

import videos from '../../../../assets/videos'
import { DataContext } from '../../../../context/data'
import { useIntersection } from '../../../../hooks/useIntersection'

import {
  Container,
  Content,
  Text,
  TextPrincipal,
  BlockText,
  Video,
  StartNow,
} from './initial_styles'
import { SearchBar } from '../../../../components'
import { ScreenContext } from '../../../../context/screen'

type MainProps = {
  id?: string
  className?: string
}

export const Main: React.FC<MainProps> = ({ id, ...props }) => {
  const ref = useRef(null as unknown as HTMLDivElement)
  const {
    body: {
      home: { initial },
    },
  } = useContext(DataContext)
  useIntersection(ref, '100px', true)

  const { showSearchOnNavbar } = useContext(ScreenContext)

  return (
    <Container {...props} id={id}>
      <Content ref={ref}>
        <BlockText>
          <TextPrincipal dangerouslySetInnerHTML={{ __html: initial.title }} />
          {!showSearchOnNavbar && (
            <>
              <StartNow>Comece agora:</StartNow>
              <SearchBar id="search-banner" />
            </>
          )}
        </BlockText>
        <Video height="720px" autoPlay muted loop>
          <source src={videos.VideoInstitutionFarMeBox} type="video/mp4" />
        </Video>
        <BlockText>
          <Text dangerouslySetInnerHTML={{ __html: initial.subtitle }}></Text>
        </BlockText>
      </Content>
    </Container>
  )
}

import React from 'react'
import { useTheme } from 'styled-components'
import { addingCurrentQueryParams } from '../../../components/helpers/url_parser'
import { Button } from '../../button'
import { urlJornada } from '../../../utils/variables'

import {
  Text,
  ActionWrap,
  Image,
  Container,
  ContainerCard,
  LeftSide,
  RightSide,
  TextInformative,
} from './content_styles'

export type CarrouselContentProps = {
  title?: string
  image: string
  imageSizeInTablet?: 'cover' | 'contain' | 'auto'
  onlyImagens?: boolean
  backgroundColor?: string
}

export const CarrouselContent: React.FC<CarrouselContentProps> = ({
  title,
  image,
  imageSizeInTablet,
  onlyImagens,
  backgroundColor,
}) => {
  const { colors } = useTheme()

  const LeftSideContent = () => (
    <>
      <Text dangerouslySetInnerHTML={{ __html: title! }} />
      <ActionWrap>
        <Button
          onClick={() => window.open(addingCurrentQueryParams(urlJornada))}
          customColor={colors.neutral.lightness}
          label="Monte sua Box"
        ></Button>
        <TextInformative> sem compromisso</TextInformative>
      </ActionWrap>
    </>
  )

  const RightSideContent = () => (
    <Image sizeInTablet={imageSizeInTablet} src={image} />
  )

  return (
    <Container>
      <ContainerCard
        onlyImagens={onlyImagens}
        backgroundColor={backgroundColor}
      >
        {onlyImagens ? (
          <Image src={image} sizeInTablet={imageSizeInTablet} />
        ) : (
          <>
            <LeftSide>
              <LeftSideContent />
            </LeftSide>
            <RightSide>
              <RightSideContent />
            </RightSide>
          </>
        )}
      </ContainerCard>
    </Container>
  )
}

import React, { useContext } from 'react'
import { DataContext } from '../../context/data'
// import DirectMe from '../../assets/images/indica_me.svg'
// import BlackFridayImage from '../../assets/images/blackfriday.svg'
import { CloseButton, Container, Content, Logo, Text } from './navbanner_styles'
import { useSession } from '../../hooks/useSession'
import { addingCurrentQueryParams } from '../helpers/url_parser'

export const NavBanner = () => {
  const {
    navbanner: { text, href, target },
  } = useContext(DataContext)
  const [shouldShowBanner, setShouldShowBanner] = useSession()
  return (
    <Container show={shouldShowBanner.keeping_open} id="banner-navbar-top">
      <Content>
        {/* <Logo src={BlackFridayImage} /> */}
        <Text
          dangerouslySetInnerHTML={{
            __html: text.replace(
              '<a>',
              `<a href="${addingCurrentQueryParams(href)}"  href="${target}">`
            ),
          }}
        />
      </Content>
      <CloseButton
        onClick={() =>
          setShouldShowBanner((prev) => ({ ...prev, keeping_open: false }))
        }
      >
        X
      </CloseButton>
    </Container>
  )
}

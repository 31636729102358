import styled from 'styled-components'

interface InputProps {
  currentRefinement: string
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 10px;
  z-index: 50;

  width: 100%;
  height: ${({ theme }) => (!theme.breakpoints.phone ? '104px' : '48px')};
`

export const SearchBarStyle = styled.div<InputProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: white;
  border-radius: 1rem;
  border: 2px solid
    ${({ currentRefinement }) => (currentRefinement ? '#FFC875' : '#342B1D')} !important;

  box-shadow: ${({ currentRefinement }) =>
    currentRefinement ? '' : '2px 2px 0px #342B1D'};
`
export const Section = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`

export const Input = styled.input`
  width: 100%;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.75rem 0 0.75rem 1.5rem;
  border-radius: 1rem;
  border: none !important;

  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
`

export const DivIcon = styled.div<InputProps>`
  height: 45px;
  padding-left: 1.25rem;
  cursor: ${({ currentRefinement }) => (currentRefinement ? 'pointer' : '')};
`

export const SpanIcon = styled.span`
  width: 2.5rem;
  height: 100%;
  display: flex;
  align-items: center;
`

export const Icon = styled.img`
  width: 16px;
  height: 16px;
`

export const SectionHits = styled.section`
  height: auto;
  row-gap: 0.5rem;
  padding: 0 0.75rem 0.75rem;
`
export const Hr = styled.hr`
  margin: 0px 0px 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffc875;
  border-radius: 1rem;
`

export const HitsResultsOrSearchFor = styled.a`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  height: auto;
  padding: 0.75rem;
  cursor: pointer;
  color: #342b1d;
  text-decoration: none;

  &:hover {
    background-color: #fff3e2;
    border-radius: 1rem;
  }
`

export const Paragraph = styled.p`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0px;
`

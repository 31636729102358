/* eslint-disable indent */
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.primary.light};
  @media (max-width: calc(${({ theme }) => theme.breakpoints.desktop} - 1px)) {
    flex-wrap: wrap;
  }
`
export const BlockText = styled.div`
  padding-top: 24px;
  padding-left: 58px;
  padding-right: 58px;
  display: flex;
  flex-direction: column;
  max-width: 450px;

  @media (max-width: calc(${({ theme }) => theme.breakpoints.phone} - 1px)) {
    max-width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    margin-bottom: ${({ theme }) => theme.spacing.internal};
  }
`
export const BlockImage = styled.div``

export const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;

  @media (width: ${({ theme }) => theme.breakpoints.desktop}),
    max-width: 1200px {
  }
  @media (max-width: 1440px) and (min-width: 1316px) {
  }
  @media (max-width: 1315px) and (min-width: 1024px) {
    margin: 5% 0 5% 5%;
  }

  @media (max-width: calc(${({ theme }) => theme.breakpoints.desktop} - 1px)) {
    gap: 1rem;
    margin: 5% 0;
    height: auto;
    width: 100%;

    flex-wrap: wrap;
  }
`

export const Text = styled.div`
  p {
    max-width: ${({ theme }) => (theme.breakpoints.desktop ? '860px' : '100%')};
    font-size: ${({ theme }) => theme.font.sizes.sm};
    line-height: ${({ theme }) => theme.font.lineHeight.distant};
    letter-spacing: 2%;
    width: 100%;
    margin: 0;
    text-align: center;
    margin-block-start: 0;
    margin-block-end: 84px;
    margin-inline-end: 0;
    margin-inline-start: 0;
  }
`
export const TextPrincipal = styled.div`
  *:not(highlight) {
    width: 100%;

    font-size: 32px;
    font-family: ${({ theme }) => theme.font.family.sliver};
    font-weight: normal;
    text-align: center;
    margin: 0 0 ${({ theme }) => (theme.breakpoints.desktop ? '32px' : '17px')};
    line-height: ${({ theme }) => theme.spacing.xs};

    @media (max-width: calc(${({ theme }) => theme.breakpoints.phone} - 1px)) {
      font-size: 24px;
    }
  }

  @media (max-width: calc(${({ theme }) => theme.breakpoints.desktop} - 1px)) {
    width: 100%;
    *:not(highlight) {
      font-size: 32px;
      font-family: ${({ theme }) => theme.font.family.sliver};
      font-weight: normal;
      margin: 0;
      line-height: ${({ theme }) => theme.spacing.sm};
    }
  }
  legend {
    font-size: ${({ theme }) => theme.font.sizes.ml};
    font-family: ${({ theme }) => theme.font.family.sliver};
    font-weight: regular;
    margin: 0;
  }
`

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  @media (width: ${({ theme }) => theme.breakpoints.desktop}),
    (max-width: 1080px) and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    width: 50%;
  }
  @media (max-width: calc(${({ theme }) => theme.breakpoints.desktop} - 1px)) {
    width: 100%;
    height: auto;
  }

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}, max-width: 1200px) {
    height: 85%;
  }
`

export const Video = styled.video`
  height: 250px;
  min-width: 100%;
  max-width: 100%;
  @media (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}, max-width: 1200px) {
    height: 250px;
    width: 400px;
  }
`

export const StartNow = styled.h2`
  font-family: ${({ theme }) => theme.font.family.default};
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
  text-align: center;
  margin: ${({ theme }) => (theme.breakpoints.desktop ? '1rem 0 0' : '0')};
  margin-bottom: 1rem;
  color: #342b1d;
`
